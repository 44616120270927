@font-face {
  font-family: 'Poppins Regular';
  src: url('assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'OpenSans Bold';
  src: url('assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('assets/fonts/Montserrat-SemiBold.ttf');
}

* {
  box-sizing: border-box;
}

#root,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Poppins Regular';
}

a {
  text-decoration: none;
}

select {
  font-family: inherit;
}

input {
  outline: none;
  font-family: inherit;
}

input:disabled {
  color: rgba(0, 0, 0, 0.26);
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  font-family: inherit;
}

button:disabled {
  color: rgba(0, 0, 0, 0.26);
  background: rgba(0, 0, 0, 0.12);
  border: none;
}

img {
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #d6dee1;
  border-radius: 2px;
  height: 30px;
}

.ant-picker-input > input {
  cursor: pointer;
}

.ant-page-header-heading-title {
  line-height: 25px;
}

@media (max-width: 768px) {
  .ant-table table {
    display: flex;
    flex-direction: column;
  }

  .ant-table thead {
    display: none;
  }

  .ant-table tr {
    display: flex;
    flex-direction: column;
    border: 1px solid #f0f0f0;
    border-bottom: none;
    margin-bottom: 20px;
  }

  .ant-table td {
    display: flex;
  }

  .ant-empty-normal {
    margin: 32px auto;
  }
}
